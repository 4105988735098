import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["name", "button"]

  check(){
    const name = this.nameTarget.value.toLowerCase().replace(/\s+/g, '');
    const fullName = this.nameTarget.dataset.fullName.toLowerCase().replace(/\s+/g, '');
    if (name == fullName) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }
}
