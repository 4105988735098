import { Controller } from "stimulus"

export default class extends Controller {

  clear(e){
    e.preventDefault();
    $(this.element).find("textarea").val('');
  }

  submit_form_js(e){
    e.preventDefault();
    const formData = new FormData(this.element)
    formData.delete('authenticity_token')
    $.ajax({
      method: "POST",
      url: this.element.action,
      data: formData,
      processData: false,
      contentType: false,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'script',
      complete: function(){ console.log('finish') }
    })
  }
}