import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "button"]

  check(){
    if (this.inputTarget.checked) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }
}
