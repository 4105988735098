import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["preview", "input"]

  connect(){
    var _this = $(this.previewTarget);
    this.defaultImageSrc = this.previewTarget.style.backgroundImage
  }

  previewImage(){
    const preview = this.previewTarget
    const input = this.inputTarget
    const file = input.files[0]
    let reader = new FileReader()

    reader.addEventListener("load", function() {
      preview.style.backgroundImage = "url(" + reader.result + ")"
      preview.classList.add('has-img')
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    } else {
      preview.style.backgroundImage = this.defaultImageSrc
      if (this.defaultImageSrc == 'url("")') {
        preview.classList.remove('has-img')
      }
    }
  }

  get defaultImageSrc() {
    return (this.data.get("defaultImageSrc"))
  }

  set defaultImageSrc(value){
    this.data.set("defaultImageSrc", value)
  }
}
