import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  triggerEvent() {
    if (this.linkTarget.dataset.id === 'free_trial_normal_free_trial') {
      fbq('track', 'StartTrial', {value: '0.00', currency: 'AUD', predicted_ltv: '0.00'});
    } else if (this.linkTarget.dataset.id === 'weightloss_normal_recipes') {
      fbq('track', 'Subscribe', {value: '0.00', currency: 'AUD', predicted_ltv: '0.00'});
    }
  }
}