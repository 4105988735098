import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radiobtn"];

  connect() {
    var obj = this.element;
    if (this.hasRadiobtnTarget) {
      var radio_btn = $(obj).find('input.radio_buttons');
      if($(radio_btn[0]).prop('checked') == true){
        $(radio_btn[0]).parents('.radio').after('<div class = "text-explanation"><b> The Become Leaner option IS</b> the individually tailored version of BARE Lean, which will guide you through your journey to becoming leaner, fitter, stronger, and more confident.</div>');
      }
      $(radio_btn[0]).click(function(){
        $(obj).find('.text-explanation').slideUp("slow", function() { this.remove();});;
        $(radio_btn[0]).parents('.radio').after('<div class = "text-explanation"><b> The Become Leaner option IS</b> the individually tailored version of BARE Lean, which will guide you through your journey to becoming leaner, fitter, stronger, and more confident.</div>');
      });
      $(radio_btn[1]).click(function(){
        $(obj).find('.text-explanation').slideUp("slow", function() { this.remove();});;
        $(radio_btn[1]).parents('.radio').after('<div class = "text-explanation"><b>The General Healthy Eating & Exercise option IS NOT</b> the individually tailored version of BARE Lean, it is a <b>GENERAL</b> version for those just wanting to eat healthy recipes and enjoy exercise at their own pace.</div>');
      });
    }
  }
}