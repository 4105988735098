import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["socialMedia"];

  openSocialLink() {
    let _this = $(event.currentTarget);
    let type = _this.data('type');
    let url = '';
    let windowSet = 'fullscreen=no,height=600,width=600';

    switch(type) {
      case "facebook":
        url = "https://www.facebook.com/groups/bareguide/?ref=pages_profile_groups_tab&source_id =1461229317526630";
        break;
      case "twitter":
        url = "https://twitter.com/intent/tweet?text=Checkout this amazing weight loss program I found, BARE Lean %0D%0A https://www.leahitsines.com.au/";
        break;
      case "pinterest":
        url = "https://pinterest.com/pin/create/button/?url=https://www.leahitsines.com.au/&media=&description=Checkout this amazing weight loss program I found, BARE Lean";
        break;
    }

    window.open(url, type, windowSet);
  }

}
