import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link"]

  initialize() {
    this.checkRadio();
  }

  switch() {
    const _this = $(event.currentTarget);
    const selector = "input[id$='" + _this.data("id") + "']";
    $(selector).prop('checked', true);
    if(!_this.hasClass("is-active")){
      _this.siblings().removeClass("is-active");
      _this.addClass("is-active");
    }
  }

  checkRadio() {
    $('.select-tab-group .select-tab').removeClass('is-active');
    $('.select-tab-group').each(function(){
      var checkedID = $(this).parents('.form-group-v').find("input[type='radio']:checked").attr("id");
      if(checkedID){
        var selector = ".select-tab[data-id='" + checkedID + "']";
        if(!$(selector).hasClass('is-active')){
          $(selector).addClass('is-active');
        }
      }
    });
  }

}
