import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "header", "selectionItem" ]

  connect(){
    this.activeCurrentSelection()
  }

  switchBareGuide(){
    const clickedItemName = event.currentTarget.dataset.itemName
    if (this.selection != clickedItemName) {
      this.selection = clickedItemName
    }
  }

  activeCurrentSelection(){
    this.headerTarget.textContent = this.selection.replace('_', ' ')
    this.selectionItemTargets.forEach((element) => {
      element.classList.toggle("active", element.dataset.itemName == this.selection)
    })
  }

  journeyRecord(){
    let currentJourneyRecord = {}
    $('#chart-container').data('journey-records').forEach((e) => {
      const tempJourneyRecord = e
      if (tempJourneyRecord.attributes.name == this.selection) {
        currentJourneyRecord = tempJourneyRecord
      }
    })
    return currentJourneyRecord
  }

  get selection() {
    return (this.data.get("currentSelection"))
  }

  set selection(value){
    this.data.set("currentSelection", value)
    this.activeCurrentSelection()
  }
}