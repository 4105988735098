import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  initialize() {
    this.display();
  }

  display() {
    // const input = this.inputTarget
    // const perc = (input.value - input.getAttribute('min')) / (input.getAttribute('max') - input.getAttribute('min')) * 100
    // input.style.backgroundSize = perc + "% 100%"
    const _this = $(this.inputTarget);
    resize(_this);
    _this.on('input', function() {
      resize($(this));
    });
    _this.on('update', function() {
      resize($(this));
    });

    function resize(_this) {
      var perc = (_this.val() - _this.attr('min')) / (_this.attr('max') - _this.attr('min')) * 100;
      _this.css("background-size", perc + "% 100%");
      _this.parents(".progress-bar-wrapper").find(".bar-number").text(_this.val());
    }

  }

}
