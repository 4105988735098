import { Controller } from "stimulus"
require("packs/tagInputs")

export default class extends Controller {
  static targets = [ "emailInput" ]

  connect() {
    $(this.emailInputTarget).tagsInput({
      'width': '100%',
      'delimiter': ',',
      'defaultText': 'Enter email',
      onAddTag: function(item) {
        $($(".tagsinput").get(0)).find(".tag").each(function() {
          if (!ValidateEmail($(this).text().trim().split(/(\s+)/)[0])) {
            $(this).addClass("badtag");
          }
        });
      },
      'onChange': function(item) {
        $($(".tagsinput").get(0)).find(".tag").each(function() {
          if (!ValidateEmail($(this).text().trim().split(/(\s+)/)[0])) {
            $(this).addClass("badtag");
          }
        });
      }

    });

    function ValidateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
      }
      return (false)
    }
  }
}
