import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.checkMetric();
  }

  switch() {
    var height_unit_area = $('.progress-bar-wrapper.devise_height').find('.bar-unit');
    var weight_unit_area = $('.progress-bar-wrapper.devise_weight').find('.bar-unit');
    var height_input = $(".progress-bar-wrapper.devise_height input[id$='user_height']");
    var weight_input = $(".progress-bar-wrapper.devise_weight input[id$='user_weight']");

    if (!$(event.currentTarget).hasClass("is-active")) {
      if (event.currentTarget.getAttribute("data-id") == 'user_metric_metric') {
        height_unit_area.text('cm');
        weight_unit_area.text('kg');
        height_input.attr('min', '150');
        height_input.attr('max', '190');
        height_input.val(height_input.attr('min'));
        weight_input.attr('min', '50');
        weight_input.attr('max', '150');
        weight_input.val(weight_input.attr('min'));
      } else {
        height_unit_area.text('inch');
        weight_unit_area.text('lb');
        height_input.attr('min', '60');
        height_input.attr('max', '75');
        height_input.val(height_input.attr('min'));
        weight_input.attr('min', '110');
        weight_input.attr('max', '330');
        weight_input.val(weight_input.attr('min'));
      }
      height_input.trigger('update');
      weight_input.trigger('update');
    }
  }

  checkMetric() {
    if($("input[id$='user_metric_metric']").prop('checked')){
      $('.progress-bar-wrapper.devise_height').find('.bar-unit').text('cm');
      $('.progress-bar-wrapper.devise_weight').find('.bar-unit').text('kg');
    }
    if($("input[id$='user_metric_imperial']").prop('checked')){
      $('.progress-bar-wrapper.devise_height').find('.bar-unit').text('inch');
      $('.progress-bar-wrapper.devise_weight').find('.bar-unit').text('lb');
    }
  }
}
