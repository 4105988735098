import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "header", "selectionItem", "carouselItem" ]

  connect(){
    this.activeCurrentSelection()
  }

  switchWeek() {
    const clickedItemName = event.currentTarget.dataset.itemName
    if (this.selection != clickedItemName) {
      this.selection = clickedItemName
    }
  }

  activeCurrentSelection(){
    this.headerTarget.textContent = this.selection
    this.selectionItemTargets.forEach((element) => {
      element.classList.toggle("active", element.dataset.itemName == this.selection)
    })
    this.carouselItemTargets.forEach((element) => {
      element.classList.toggle("hide", element.dataset.itemName != this.selection)
      if (element.dataset.itemName == this.selection && !element.classList.contains('slick-initialized')) {
        $(element).slick({
          draggable: false,
          prevArrow: '<a class="prev-arrow slick-arrow"><i class="far fa-angle-left"></i></a>',
          nextArrow: '<a class="next-arrow slick-arrow"><i class="far fa-angle-right"></i></a>'
        })
      }
    })
  }

  journeyRecord(){
    let currentJourneyRecord = {}
    $('#chart-container').data('journey-records').forEach((e) => {
      const tempJourneyRecord = e
      if (tempJourneyRecord.attributes.name == this.selection) {
        currentJourneyRecord = tempJourneyRecord
      }
    })
    return currentJourneyRecord
  }

  get selection() {
    return (this.data.get("currentSelection"))
  }

  set selection(value){
    this.data.set("currentSelection", value)
    this.activeCurrentSelection()
  }
}
