import { Controller } from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {

  connect() {
    $(document).on('turbolinks:load', function() {
      const modal = new Foundation.Reveal($('#initial-signin-modal'))
      modal.open()
      $(document).on('closed.zf.reveal', modal, function () {
        $.ajax({
          url: '/set_initial_signin',
          type: 'POST',
          processData: false,
          contentType: false,
          error: function(jqXHR, textStatus, errorThrown){
            console.log(textStatus);
          },
          success: function(data){
            $('.reveal-overlay').remove()
            window.location = ''
          }
        });
      });
    });
  }

  disconnect() {
    $(document).on('turbolinks:load', function() {
      $('.reveal-overlay').remove()
    })
  }

  close() {
    this.commonProcess()
  }

  journeyRecords() {
    this.commonProcess('/journey_records')
  }

  commonProcess(url = '') {
    $.ajax({
      url: '/set_initial_signin',
      type: 'POST',
      processData: false,
      contentType: false,
      error: function(jqXHR, textStatus, errorThrown){
        console.log(textStatus);
      },
      success: function(data){
        $('.reveal-overlay').remove()
        window.location = url
      }
    });
  }
}
