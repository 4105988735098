import { Controller } from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  static targets = [];
  connect(){
    this.showOnboardingTips();
    console.log("onboarding test");
  }
  showOnboardingTips(){
    var elementID = ['#stepA-dash','#stepB-guide','#stepC-journey','#stepD-forum','#stepE-section'];
    var tipHeading = ['This Is Where You Can View and Track Your Journey',
                      'Recipes, Resources, Meal Planners & Shopping Lists',
                      'This Is Where You Can Track Your BARE Lean Journey',
                      'BARE Lean Chat',
                      'Recipes and Workouts'];
    var tipContent = ['Welcome to your dashboard aka your home page. This is where you will be able have an overview of your progress, results and overall BARE Lean journey.',       
    'Welcome to the fun part, where you’ll find everything you need to start your BARE Lean journey. This is where you’ll find your tailored recipes (if using become Leaner), your Lean resources and customisable shopping lists.',
    'Wanting to track your progress throughout your journey? Welcome to our journey section where you can track your measurements, well-being and progress. There is a fortnightly check in that you’ll be prompted for. ',
    'Our forum is designed so you can ask questions, gain support and have all of your BARE Lean questions answered.',
    'If you’re using Become Leaner, these recipes are already tailored to your energy deficit and portioned for you to reach your goals. Everything you need is in here! '];
    var url = ['/','/bare_guides','/journey_records','','/bare_guides'];

    var clickCount = 0;
    var currentPagePath = window.location.pathname;
    if(currentPagePath == url[4]){
      clickCount = 4;
      updateContent(clickCount);
      $('#cloned-element').remove();
      cloneElement(elementID[4]);
    }
    getPosition(elementID[clickCount]);
    showCloneElement(elementID[clickCount])
    $('#onboarding-tip-button').click(function(){
      if($('#onboarding-tip-button').html()=='Done !'){
        loading();
      }
      else{
        clickCount++;
        if(clickCount==4){
          jumpTo(url[clickCount]);
        }
        getPosition(elementID[clickCount]);
        updateContent(clickCount);
        if(clickCount==4){
          cloneElement(elementID[4])
        }
        else{
          cloneElement(elementID[clickCount]);
        }
      }
    });

    function getPosition(elementID){
      var x = $(elementID).offset().left;
      var y = $(elementID).offset().top;
      var tmpH = 0;
      var tmpW = 0;
      var sidenavW = $('.consumer-sidenav').width();
      if(elementID == '#stepE-section'){
        tmpW = $('#stepE-section').width()*0.35;
        tmpH = $('#stepE-section').height()*0.2;
      }
      $('#onboarding-tip').offset({'top':y-16+tmpH,'left':x+sidenavW+20+tmpW});
      $('#onboarding-tip').removeClass('hide')
    };

    function updateContent(count){
      var tip = $('#onboarding-tip');
      tip.find('.popup-tip .tip-heading').html(tipHeading[count]);
      tip.find('.popup-tip .tip-content').html(tipContent[count]);
      if(count==4){
        $('#onboarding-tip-button').html('Done !');
      }
    };

    function showCloneElement(elementID){
      var x = $(elementID).offset().left;
      var y = $(elementID).offset().top;
      $('#modal-highlight li').offset({'top':y,'left':x});
      if(clickCount == 4)
        $('#modal-highlight div.guide-section').offset({'top':y,'left':x});
      $('#modal-highlight').removeClass('hide');
    };

    function cloneElement(elementID){
      $('#cloned-element').remove();
      $(elementID).clone().attr('id','#cloned-element').appendTo('#modal-highlight');
      showCloneElement(elementID);
    }

    function jumpTo(url){
      location.href = url;
    };

    function loading(){
      $('#onboarding-tip').addClass('hide');
      $('#modal-highlight').addClass('hide');
      $('#loader').removeClass('hide');
      setTimeout(function(){jumpTo(url[0] + '?&initial=true')},2000)
    };
  }
} 