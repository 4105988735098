import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "eyeIcon"]

  onSwitch(e){
    e.preventDefault()
    const passwordInput = this.inputTarget
    const eyeIcon = this.eyeIconTarget
    if (eyeIcon.classList.contains('fa-eye-slash')) {
      passwordInput.type = 'password'
      eyeIcon.classList.remove('fa-eye-slash')
      eyeIcon.classList.add('fa-eye')
    } else {
      passwordInput.type = 'text'
      eyeIcon.classList.remove('fa-eye')
      eyeIcon.classList.add('fa-eye-slash')
    }
  }
}
