import {
  Controller
} from "stimulus"
var Highcharts = require('highcharts');


export default class extends Controller {
  static targets = ["header", "selectionItem", "container"]

  connect() {
    this.selection = 'weight'
  }

  switchStatisticsGraph() {
    const clickedItemName = event.currentTarget.dataset.itemName
    const currentWeek = event.currentTarget.dataset.currentWeek
    this.onboardingWeight = event.currentTarget.dataset.onboardingWeight
    if (this.weekSequence != currentWeek) {
      if (currentWeek == 1) {
        this.weekSequence = 0
      } else {
        this.weekSequence = currentWeek / 2
      }
    }
    if (this.selection != clickedItemName) {
      this.selection = clickedItemName
    }
  }

  activeCurrentSelection() {
    this.headerTarget.textContent = this.selection.replace('_', ' ')
    this.selectionItemTargets.forEach((element) => {
      element.classList.toggle("active", element.dataset.itemName == this.selection)
    })
    var tmpname = this.selection.replace('_', ' ')
    tmpname = tmpname.split(" ")
    for (let index = 0; index < tmpname.length; index++) {
      tmpname[index] = tmpname[index].charAt(0).toUpperCase()+tmpname[index].slice(1);
    }
    const seriesName = tmpname.join(' ')
    const seriesData = this.statisticsArray()
    const min = Math.min(...seriesData)
    const floor = min > 10 ? min - 10 : 0
    const valueSuffix = this.selection == 'weight' ? this.data.get("weightUnit") : this.data.get("lengthUnit")
    const onboardingWeight = this.onboardingWeight
    // console.log(seriesName) // name
    // console.log(seriesData[this.weekSequence], seriesData[0]); // number
    // console.log(valueSuffix); //unit
    if (typeof seriesData[this.weekSequence] != 'undefined' && typeof valueSuffix != 'undefined') {
      for (var i = this.weekSequence; i >= 0; i--) {
        if (seriesData[i] == '-' && i != 0) {
          continue;
        } else {

          $("[data-number-pane-number]").html(seriesData[i] == '-' ? '0.00' : seriesData[i].toFixed(2));
          $("[data-number-pane-unit]").html(valueSuffix);
          if (seriesName == 'Weight' && i == 0 && seriesData[i] == '-' && typeof onboardingWeight != 'undefined') { // use onborading weight when user not finish initial checkin
            if (seriesData[0] == '-') {
              seriesData[0] = parseFloat(onboardingWeight)
            }
            $("#current_unit").children('.title').html(`Current ${seriesName}`)
            $("#current_unit").children('.content').html(`${onboardingWeight} ${valueSuffix}`)
            $("#starting_unit").children('.title').html(`Starting ${seriesName}`)
            $("#starting_unit").children('.content').html(`${onboardingWeight} ${valueSuffix}`)
            $("[data-number-pane-number]").html(`${onboardingWeight}`);
          } else {
            $("#current_unit").children('.title').html(`Current ${seriesName}`)
            if (seriesData[i]=='-') {
              seriesData[i]=0
            }
            if (seriesData[0]=='-') {
              seriesData[0]=0
            }
            $("#current_unit").children('.content').html(`${seriesData[i].toFixed(2)} ${valueSuffix}`)
            $("#starting_unit").children('.title').html(`Starting ${seriesName}`)
            if (seriesName == 'Weight') {
              if (typeof onboardingWeight == 'undefined' || onboardingWeight == '0.00') {
                $("#starting_unit").children('.content').html(`0.00 ${valueSuffix}`)
              } else{
                $("#starting_unit").children('.content').html(`${onboardingWeight} ${valueSuffix}`)
              }
            } else {
              $("#starting_unit").children('.content').html(`${seriesData[0].toFixed(2)} ${valueSuffix}`)
            }
          }
          
          $("#difference_unit").children('.content').html(`${Math.abs(seriesData[i] - seriesData[0]).toFixed(2)} ${valueSuffix}`)
             
          break;
        }
      }
    }

    for (var i = seriesData.length - 1; i >= 0; i--) {
      if (seriesName == 'Weight') {
        if (i == 0 && (seriesData[0] == 0||seriesData[0] == '-')) {
          if (typeof onboardingWeight != 'undefined') {
            seriesData[0] = parseFloat(onboardingWeight)
          } else {
            seriesData[0] = 0
          }
        } else if (seriesData[i] == '-') {
          seriesData[i] = 0
        }
      } else if (seriesData[i] == '-') {
        seriesData[i] = 0
      }
    }

    Highcharts.chart('chart-container', {
      chart: {
        type: 'areaspline',
        marginLeft: 0,
        marginRight: 0
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: [
          'Initial',
          'Week 2',
          'Week 4',
          'Week 6',
          'Week 8',
          'Week 10',
          'Week 12'
        ],
        labels: {
          style: {
            color: "#AAAAAA",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: "500"
          }
        }
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        floor: floor,
        labels: {
          enabled: false
        },
        title: {
          enabled: false
        }
      },
      tooltip: {
        shared: true,
        valueSuffix: valueSuffix
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          color: '#4FD1D1'
        },
        areaspline: {
          fillOpacity: 0.2
        }
      },
      series: [{
        name: seriesName,
        data: seriesData
      }]
    });
  }

  statisticsArray() {
    const tempArray = []
    $(this.containerTarget).data('journey-records').forEach((e) => {
      const value = e.attributes[this.selection]
      tempArray.push((value == null || value == "") ? '-' : parseFloat(value))
    })
    this.onboardingWeight = $(this.containerTarget).data('onborarding-weight')
    return tempArray
  }

  get selection() {
    return (this.data.get("currentSelection"))
  }

  set selection(value) {
    this.data.set("currentSelection", value)
    this.activeCurrentSelection()
  }
}