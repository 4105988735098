const jstz = require("jstz")

export var GetTimezone = {

  init: function() {

    $(document).ready(function() {

      if (GetTimezone.loaded){
        return;
      }
      else{
        GetTimezone.loaded = true;
      };

      var tz = jstz.determine();
      document.cookie = `timezone=${tz.name()}`;
      console.log("---- Timezone: " + tz.name());
    });
  },
};
