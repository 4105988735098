import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "header", "selectionItem", "contentItem" ]

  connect(){
    this.activeCurrentSelection()
  }

  switchWeek() {
    const clickedItemName = event.currentTarget.dataset.itemName
    if (this.selection != clickedItemName) {
      this.selection = clickedItemName
    }
  }

  activeCurrentSelection(){
    this.headerTarget.textContent = this.selection.replace('_', ' ')
    this.selectionItemTargets.forEach((element) => {
      element.classList.toggle("active", element.dataset.itemName == this.selection)
    })
    this.contentItemTargets.forEach((element) => {
      element.classList.toggle("hide", element.dataset.itemName != this.selection)
    })
  }

  get selection() {
    return (this.data.get("currentSelection"))
  }

  set selection(value){
    this.data.set("currentSelection", value)
    this.activeCurrentSelection()
  }
}